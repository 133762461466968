body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.noscroll {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.noscroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.noScroll {
  overflow: hidden;
  height: 100%;
}

.fullWidth {
  width: calc(100vw - (100vw - 100%));
}

.customColor{
  background-color: #9e89c7;
}

.custom-shape-divider-top-1690396892 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1690396892 svg {
  position: relative;
  display: block;
  width: calc(155% + 1.3px);
  height: 121px;
}

.custom-shape-divider-top-1690396892 .shape-fill {
  fill: #ffffff;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
